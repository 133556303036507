import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from '../App';
import Login from '../Login/Login';
import Chatbot from '../pages/Chatgpt';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/login" element={<Login />} />
        <Route path="/chatbot" element={<Chatbot/>} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
