import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCar, FaRegSquare, FaFileAlt, FaVideo } from 'react-icons/fa';
import './App.css';

const CACHE_EXPIRATION_TIME = 24 * 60 * 60 * 100;

const FileItem = ({ file }) => {
  const fileNameWithoutExtension = file.name.substring(0, file.name.lastIndexOf('.')) || file.name;

  const fileExtension = file.name.split('.').pop().toLowerCase();
  const isVideoFile = ['mp4', 'avi'].includes(fileExtension);
  const icon = isVideoFile ? <FaVideo className="file-icon" /> : <FaFileAlt className="file-icon" />;

  return (
    <li>
      <a href={file.webViewLink} target="_blank" rel="noopener noreferrer">
        {icon}
        {fileNameWithoutExtension}
      </a>
    </li>
  );
};

const FolderItem = ({ file, onClick, icon, isSelected }) => (
  <li
    onClick={() => onClick(file.id, file.name)}
    style={{ cursor: 'pointer', color: isSelected ? 'blue' : 'black' }}
  >
    {icon}
    {file.name}
  </li>
);

const LoadingSpinner = () => (
  <div style={{ padding: '20px' }}>
    <div className="spinner"></div>
    <p>Carregando...</p>
  </div>
);

const SearchBar = ({ searchTerm, setSearchTerm, handleSearch }) => (
  <form onSubmit={handleSearch} style={{ marginBottom: '20px' }}>
    <input
      type="text"
      placeholder="Pesquisar..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      style={{ padding: '5px', width: '80%' }}
    />
    <button type="submit" style={{ marginLeft: '10px' }}>Pesquisar</button>
  </form>
);

const normalizeString = (str) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/ç/g, 'c')
    .replace(/ñ/g, 'n')
    .replace(/[^\w\s]/gi, '');
};

const App = () => {

  const rootFolderId = '1MloFWgoRTY4Yrwn6OozM4hQ-K82nbOc2';
  const apiKey = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY;

  const [rootFolders, setRootFolders] = useState([]);
  const [subFolders, setSubFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentFolderId, setCurrentFolderId] = useState(rootFolderId);
  const [currentFolderName, setCurrentFolderName] = useState('MANUAIS');

  const [selectedRootFolderId, setSelectedRootFolderId] = useState(null);
  const [selectedSubFolderId, setSelectedSubFolderId] = useState(null);

  const getCache = (folderId) => {
    const cachedData = localStorage.getItem(folderId);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_EXPIRATION_TIME) {
        return data;
      } else {
        localStorage.removeItem(folderId);
      }
    }
    return null;
  };

  const setCache = (folderId, data) => {
    const cacheEntry = {
      data,
      timestamp: Date.now(),
    };
    localStorage.setItem(folderId, JSON.stringify(cacheEntry));
  };

  const fetchFilesFromGoogleDrive = async (folderId) => {
    let query = `'${folderId}' in parents and trashed=false`;

    const params = {
      q: query,
      fields: 'files(id, name, mimeType, webViewLink, parents)',
      key: apiKey,
      pageSize: 1000,
    };

    const response = await axios.get(`https://www.googleapis.com/drive/v3/files`, { params });
    return response.data.files.sort((a, b) => a.name.localeCompare(b.name));
  };

  const fetchFiles = async (folderId, setState, searchTerm = '') => {
    setLoading(true);
    setError(null);

    try {
      const cachedFiles = getCache(folderId);
      if (cachedFiles && !searchTerm) {
        setState(cachedFiles);
        setLoading(false);
        return;
      }

      const files = await fetchFilesFromGoogleDrive(folderId);
      const normalizedTerm = normalizeString(searchTerm.toLowerCase());

      const filteredFiles = files.filter(file => {
        const normalizedFileName = normalizeString(file.name.toLowerCase());
        return normalizedFileName.includes(normalizedTerm);
      });

      setState(filteredFiles);
      if (!searchTerm) {
        setCache(folderId, filteredFiles);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFiles(rootFolderId, setRootFolders);
  }, [apiKey]);

  const handleRootFolderClick = (folderId, folderName) => {
    fetchFiles(folderId, setSubFolders);
    setFiles([]);
    setSearchTerm('');
    setCurrentFolderId(folderId);
    setCurrentFolderName(folderName);
    setSelectedRootFolderId(folderId);
    setSelectedSubFolderId(null);
  };

  const handleSubFolderClick = (folderId, folderName) => {
    fetchFiles(folderId, setFiles);
    setSearchTerm('');
    setCurrentFolderId(folderId);
    setCurrentFolderName(folderName);
    setSelectedSubFolderId(folderId);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchFiles(currentFolderId, setFiles, searchTerm);
  };

  const shouldShowSearchInput = files.length > 10;

  return (
    <div className="containerFull">

      <div className="container">
        <div className="column">
          <h3>MÓDULOS</h3>
          <ul>
            {rootFolders.map(folder => (
              <FolderItem
                key={folder.id}
                file={folder}
                onClick={handleRootFolderClick}
                icon={<FaRegSquare className="montadora-icon" />}
                isSelected={folder.id === selectedRootFolderId}
              />
            ))}
          </ul>
        </div>

        <div className="column">
          <h3>MONTADORA | SISTEMA</h3>
          <ul>
            {subFolders.map(folder => (
              <FolderItem
                key={folder.id}
                file={folder}
                onClick={handleSubFolderClick}
                icon={<FaCar className="modelo-icon" />}
                isSelected={folder.id === selectedSubFolderId}
              />
            ))}
          </ul>
        </div>

        <div className="column">
          <h3>{currentFolderName}</h3>
          {shouldShowSearchInput && (
            <SearchBar
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              handleSearch={handleSearch}
            />
          )}
          {loading ? (
            <LoadingSpinner />
          ) : error ? (
            <div>Error: {error}</div>
          ) : files.length > 0 ? (
            <ul>
              {files.map(file => (
                <FileItem key={file.id} file={file} />
              ))}
            </ul>
          ) : (
            <div></div>
          )}
        </div>

      </div>

    </div>
  );
};

export default App;