import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAt7ftlYn9PRsl1wTHFsCsli_qXZjvfsZE",
  authDomain: "auto-diesel-14e29.firebaseapp.com",
  projectId: "auto-diesel-14e29",
  storageBucket: "auto-diesel-14e29.appspot.com",
  messagingSenderId: "1065858243967",
  appId: "1:1065858243967:web:ac2afa0da2449979464e4a"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { app, auth, db };
