import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';
import './Chatbot.css';
import HeaderChat from '../../components/HeaderChat';
import FooterChat from '../../components/FooterChat';

const Chatbot = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [examples, setExamples] = useState([]);
    const [displayedMessage, setDisplayedMessage] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const messagesEndRef = useRef(null);

    const systemMessage = {
        role: "system",
        content: "Você é um assistente especializado em reparação de veículos Diesel, Caminhonetes, Caminhões e Ônibus. Focado em diagnósticos de defeitos e códigos DTC EOBD DIESEL, Injeção Eletrônica Diesel, Regulagem de Motores Diesel, Lubrificantes de motores Diesel. Responda com clareza e precisão. Seu nome é Autodoc. Recomende ao usuário utilizaro a Biblioteca do Autodoc, para verificar os dados nos manuais técnicos."
    };

    useEffect(() => {
        const fetchExamples = async () => {
            try {
                const response = await axios.get('https://ecommerce-app-strapi-expo.s3.sa-east-1.amazonaws.com/assistente/assistente-memoria.json');
                setExamples(response.data.examples);
            } catch (error) {
                console.error("Erro ao carregar exemplos:", error);
            }
        };

        fetchExamples();
    }, []);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, displayedMessage]);

    const createContextMessages = (examples) => {
        let contextMessages = [];
        examples.forEach(example => {
            contextMessages.push({ role: "user", content: example.question });
            contextMessages.push({ role: "assistant", content: example.answer });
        });
        return contextMessages;
    };

    const temperature = 0;
    const maxTokens = 650;

    const handleSend = async () => {
        if (input.trim() === '') return;
        const userMessage = { role: 'user', content: input };
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setInput('');
        setLoading(true);

        const contextMessages = createContextMessages(examples);

        try {
            const response = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                    model: "gpt-4o-mini",
                    messages: [systemMessage, ...contextMessages, ...messages, userMessage],
                    temperature: temperature,
                    max_tokens: maxTokens
                },
                {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            const botMessageContent = response.data.choices[0].message.content;
            typeMessage(botMessageContent);
        } catch (error) {
            console.error("Erro ao chamar a API da OpenAI:", error);
            setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: 'Desculpe, ocorreu um erro ao processar sua solicitação.' }]);
            setLoading(false);
        }
    };

    const typeMessage = (messageContent) => {
        let index = 0;
        setDisplayedMessage('');
        setIsTyping(true); // Inicia a animação de digitação

        const typingInterval = setInterval(() => {
            setDisplayedMessage((prev) => prev + messageContent[index]);
            index++;

            // Scroll para o final da lista de mensagens
            if (messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            }

            if (index === messageContent.length) {
                clearInterval(typingInterval);
                setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: messageContent }]);
                setLoading(false);
                setIsTyping(false);
            }
        }, 10); // Ajuste o tempo conforme necessário para a velocidade de digitação
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSend();
        }
    };

    return (
        <div className="chatbot-container">
            <div className="chatbot-header">
                <HeaderChat/>
            </div>
            <div className="messages">
                {messages.map((message, index) => (
                    <div key={index} className={`message ${message.role}`}>
                        <img
                            src={message.role === 'user' ? '/images/user-avatar.png' : '/images/bot-avatar.png'}
                            alt={`${message.role} avatar`}
                            className="avatar"
                        />
                        <span className="message-content">{message.content}</span>
                    </div>
                ))}

                {isTyping && (
                    <div className="message bot typing">
                        <img src="/images/bot-avatar.png" alt="bot avatar" className="avatar" />
                        <span className="message-content">{displayedMessage}</span>
                    </div>
                )}
                <div ref={messagesEndRef} />
            </div>
            <div className="input-container">
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Digite sua mensagem..."
                />
                <button onClick={handleSend} disabled={loading}>
                    {loading ? <FaSpinner className="spinner" /> : 'Enviar'}
                </button>

            </div>

        </div>
    );
};

export default Chatbot;