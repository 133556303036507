import React from 'react';
import './HeaderChat.css';

const HeaderChat = () => {
  return (
    <header className="header">
      <div className="logo-container">

          <img src="../images/logo-marca.png" alt="Logo" className="logo" />

      </div>
    </header>
  );
};

export default HeaderChat;
